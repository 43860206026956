import {Injectable} from '@angular/core';
import {of as observableOf, Observable} from 'rxjs';
import {PeriodsService} from './periods.service';
import {OutlineData, VisitorsAnalyticsData} from '../data/visitors-analytics';
import {ApiUrlService} from '../../services/shared-services/api-url.service';
import {ApiService} from '../../services/shared-services/api.service';
import {API_URLS} from '../../constants/api-urls';
import {map} from 'rxjs/operators';

@Injectable()
export class VisitorsAnalyticsService extends VisitorsAnalyticsData {
  serviceUrl: string;

  constructor(private periodService: PeriodsService,
              private apiUrlService: ApiUrlService,
              private apiService: ApiService) {
    super();
    this.apiUrlService.getUrl().subscribe(x => {
      this.serviceUrl = x.url;
    });
  }


  private innerLinePoints: number[] = [];
  private outerLinePoints: OutlineData[] = [];
  private pieChartValue = 8000;

  getWeekWiseDurationData() {
    return this.apiService.get(this.serviceUrl + API_URLS.ATTENDANCE.WEEK_WISE_DURATION).pipe(
      map(response => {
        return response.replyMessage;
      }));
    // this.apiService.get(this.serviceUrl + API_URLS.ATTENDANCE.WEEK_WISE_DURATION).subscribe(
    //   (res) => {
    //     this.outerLinePoints = res.replyMessage;
    //     console.log('API' + this.outerLinePoints);
    //   });
  }

  getWeekWiseExpectedDurationData() {
    return this.apiService.get(this.serviceUrl + API_URLS.ATTENDANCE.WEEK_WISE_EXPECTED_DURATION).pipe(
      map(response => {
        return response.replyMessage;
      }));
    // this.apiService.get(this.serviceUrl + API_URLS.ATTENDANCE.WEEK_WISE_DURATION).subscribe(
    //   (res) => {
    //     this.outerLinePoints = res.replyMessage;
    //     console.log('API' + this.outerLinePoints);
    //   });
  }

  // private getInnerLineData(): number[] {
  //   this.getWeekWiseExpectedDurationData().forEach(data => {
  //     // this.pieChartValue = data.length;
  //     // for (let i = 0; i < data.length; i++) {
  //     this.innerLinePoints = data;
  //     // console.log('data :' + this.innerLinePoints);
  //     // }
  //   });
  //   return this.innerLinePoints;
  // }

  private generateOutlineLineData(): OutlineData[] {
    this.getWeekWiseDurationData().forEach(data => {
      // this.pieChartValue = data.length;
      for (let i = 0; i < data.length; i++) {
        this.innerLinePoints[i] = 6660;
        this.outerLinePoints.push({
          label: data[i].label,
          value: data[i].value,
        });
      }
    });
    return this.outerLinePoints;
    // return this.getWeekWiseDurationData().pipe(
    //   map(data => {
    //     return {
    //       label: data.label,
    //       value: data.value,
    //     };
    //   }));
  }

  getInnerLineChartData(): Observable<number[]> {
    return observableOf(this.innerLinePoints);
  }

  getOutlineLineChartData(): Observable<OutlineData[]> {
    return observableOf(this.generateOutlineLineData());
  }

  getPieChartData(): Observable<number> {
    return observableOf(this.pieChartValue);
  }
}

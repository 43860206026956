import {Component, OnInit} from '@angular/core';
import {NotificationUpdateRequest} from '../../../pages/notification/notification.model';
import {Subject} from 'rxjs';
import {API_URLS} from '../../../constants/api-urls';
import {HttpParams} from '@angular/common/http';
import {map, takeUntil} from 'rxjs/operators';
import {NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService} from '@nebular/theme';
import {UserData} from '../../../@core/data/users';
import {LayoutService} from '../../../@core/utils';
import {AuthenticationService} from '../../../authentication/authentication.service';
import {JwtUtilService} from '../../../services/shared-services/jwt-util.service';
import {ApiService} from '../../../services/shared-services/api.service';
import {ApiUrlService} from '../../../services/shared-services/api-url.service';
import {Router} from '@angular/router';
import {EmployeeService} from '../../../services/module-based-services/employee.service';

@Component({
  selector: 'ngx-header-info',
  templateUrl: './header-info.component.html',
  styleUrls: ['./header-info.component.scss'],
})
export class HeaderInfoComponent implements OnInit {
  isModalOpen = false;
  serviceUrl: string;
  userRole = null;
  userId = null;
  notificationList = [];
  notification: any;
  page: number = 0;
  size: number = 10;
  count: number;
  dropdownVisible = false;
  notificationVisible = false;
  notificationAll = false;
  updateRequest: NotificationUpdateRequest = new NotificationUpdateRequest();


  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  imgPath: string;
  userName: string;
  employeeProfilePicture: any;
  pictureUpload: boolean = false;
  picture = 'assets/images/avatar.png';
  currentTheme = 'default';
  userMenu = [{title: 'Profile', link: '/pages/profile'},
    {title: 'Reset Password', link: '/reset-password'},
    {title: 'Log out', link: '/logout'}];

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private userService: UserData,
              private layoutService: LayoutService,
              private breakpointService: NbMediaBreakpointsService,
              private authService: AuthenticationService,
              private jwtUtilService: JwtUtilService,
              private apiService: ApiService,
              private apiUrlService: ApiUrlService,
              private employeeService: EmployeeService,
              private router: Router) {
    this.apiUrlService.getUrl().subscribe((x) => {
      this.serviceUrl = x.url;
    });
    this.userRole = authService.getRole();
    this.userId = authService.getUser();
  }

  ngOnInit(): void {
    this.currentTheme = this.themeService.currentTheme;
    const tokenInfo = localStorage.getItem('auth_app_token');
    const userInfo = this.jwtUtilService.getDataFromJWT(JSON.parse(tokenInfo).value);
    this.user = this.authService.getUser();
    const stringArray = this.user?.name.split(' ');

    this.userName = this.authService.getUser()?.name;

    this.userService.getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => this.user = users.nick);

    const {xl} = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({name}) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);
    this.getNotificationList();
    this.getCount();
  }
  imageData(value) {
    return `data:image/jpeg;base64,${value}`;
  }

  getEmployeeProfilePicture() {
    const employeeId = +this.user.id;
    if (employeeId) {
      this.employeeService.getProfilePicture(employeeId).subscribe(value => {
        if (value.replyMessage.file) {
          this.employeeProfilePicture = this.imageData(value.replyMessage.file);
        } else {
          this.pictureUpload = true;
          // if (this.userInfo.gender.toLowerCase() === 'male') {
          //   this.imgPath = 'assets/images/avatar.png';
          // } else {
          //   this.imgPath = 'assets/images/avatar-female.png';
          // }
          this.employeeProfilePicture = this.picture;
        }
      }, error => {
      });
    }
  }
  getNotificationList() {
    const params = new HttpParams()
      .set('size', this.size);
    this.apiService.get(this.serviceUrl + API_URLS.NOTIFICATION.GET_LIST, params).subscribe(
      (res) => {
        this.notificationList = res.content;
        this.size = res.totalElements;
      });
  }

  closeDropdown(): void {
    this.dropdownVisible = false;
  }

  getCount() {
    this.apiService.get(this.serviceUrl + API_URLS.NOTIFICATION.GET_COUNT).subscribe(
      (res) => {
        this.count = res.replyMessage;

      });
  }

  showMore() {
    localStorage.setItem('size', String(this.size));
    this.router.navigate(['/pages/notification']);
  }
  colour(seen: boolean) {
    if (seen) {
      return '#ffffff';
    } else {
      return '#afafaf';
    }
  }

  getNotification(notificationId: number) {
    const params = new HttpParams()
      .set('notificationId', notificationId);
    this.apiService.get(this.serviceUrl + API_URLS.NOTIFICATION.GET_NOTIFICATION, params).subscribe(
      (res) => {
        this.notification = res.replyMessage;
        this.notificationVisible = !this.notificationVisible;
        this.updateRequest.notificationId = this.notification?.id;
        this.updateRequest.employeeId = this.notification?.createdBy;
        this.openModal();
        if (this.notification.seen === false) {
          this.apiService.put(this.serviceUrl +
            API_URLS.NOTIFICATION.UPDATE_NOTIFICATION, this.updateRequest).subscribe(
            () => {
            });
        }
      });
  }

  openModal(): void {
    this.isModalOpen = true;
    this.closeDropdown(); // Close the dropdown when the modal is opened
  }


  // ngOnDestroy() {
  //   this.destroy$.next();
  //   this.destroy$.complete();
  // }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();
    return false;
  }

}

import {Injectable} from '@angular/core';
import jwtDecode from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  barerInfo = null;
  userInfo = null;
  permissions = null;
  role = null;

  constructor() {
    this.barerInfo = localStorage.getItem('auth_app_token');

    const decodedToken = jwtDecode(this.barerInfo);
    this.userInfo = JSON.parse(decodedToken['user']);
    this.permissions = decodedToken['privileges'];
    this.role = decodedToken['roles'][0];
  }

  getUser() {
    return this.userInfo;
  }

  getPermissions() {
    return this.permissions;
  }

  getRole() {
    return this.role;
  }
}

import {Injectable} from '@angular/core';
import Swal, {SweetAlertResult} from 'sweetalert2'

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor() {
  }

  showSuccessAlert(method: string, message: string) {
    Swal.fire('Successfully ' + method + ' ' + message + ' data', '', 'success')
  }

  showSuccessAlertMessage(message: string) {
    Swal.fire(message);
  }

  showErrorAlert(message: string, method?: string) {
    if (method) {
      Swal.fire('Failed to ' + method + ' ' + message + ' data', '', 'error');
    }
    Swal.fire(message, '', 'error');
  }

  showGeneralErrorAlert() {
    Swal.fire('Something went wrong', '', 'error')
  }

  showWarningAlert(): Promise<any> {
    return Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Confirm',
      confirmButtonColor: '#58FB89',
      cancelButtonText: 'Cancel',
      cancelButtonColor: '#FF5858'
    })
  }


}

import {ApiUrl} from "./api-url";
import {Observable, of as observableOf} from "rxjs";
import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ApiUrlService extends ApiUrl {
  private urls = {
    url: environment.api_url,
  };

  public getUrl(): Observable<any> {
    return observableOf(this.urls);
  }
}

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';


export interface Url {
  serviceName: string;
  url: string;
}

@Injectable({
  providedIn: 'root',
})
export abstract class ApiUrl {
  protected url: Url;
  name: string;

  constructor() {

  }

  public abstract getUrl(): Observable<Url[]>;
}

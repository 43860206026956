<div class="header-container-mobile">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
  </div>
</div>

<!--<div class="header-container">-->
<!--  <nb-actions size="small">-->
<!--    <nb-action class="user-action" *nbIsGranted="['view', 'user']">-->
<!--      <nb-user [nbContextMenu]="userMenu" [onlyPicture]="userPictureOnly" [name]="userName" [picture]="picture">-->
<!--      </nb-user>-->
<!--    </nb-action>-->
<!--  </nb-actions>-->
<!--</div>-->

export class PermissionList {

  public static attendance = {
    approve: 'attendance_approve',
    dailyReport: 'attendance_daily_report',
    dailyAttendance: 'attendance_get_daily',
    getInfo: 'attendance_get_info',
    jobCard: 'attendance_job_card',
    monthlyReport: 'attendance_monthly_report',
    saveDaily: 'attendance_save_daily',
    updateInfo: 'attendance_update_info',
    updateRequest: 'attendance_update_request',
    updateRequestList: 'attendance_update_request_list',
    uploadFile: 'attendance_upload_file',
  };

  public static employee = {
    basicInfo: 'employee_basic_info',
    delete: 'employee_delete',
    getAll: 'employee_get_all',
    getAllName: 'employee_get_all_names',
    getById: 'employee_get_by_id',
    saveInfo: 'employee_save_info',
    updateInfo: 'employee_update_info',
  };

  public static file = {
    download: 'file_download',
    upload: 'file_upload',
  };

  public static holiday = {
    getAll: 'holiday_get_all',
    saveInfo: 'holiday_save_info',
  };
  public static hrInfo = {
    delete: 'hrinfo_delete',
    getAll: 'hrinfo_get_all',
    getById: 'hrinfo_get_by_id',
    saveInfo: 'hrinfo_save_info',
    updateInfo: 'hrinfo_update_info',
  };

  public static leaveApplication = {
    approve: 'leave_application_approve',
    getAll: 'leave_application_get_all',
    getByEmp: 'leave_application_get_by_emp_id',
    getBySupervisor: 'leave_application_get_by_supervisor',
    saveInfo: 'leave_application_save_info',
  };

  public static leaveBalance = {
    getAll: 'leave_balance_get_all',
    getByEmp: 'leave_balance_get_by_emp_id',
    saveInfo: 'leave_balance_save_info',
  };

  public static leaveType = {
    getAll: 'leave_type_get_all',
    getByGender: 'leave_type_get_by_gender',
    saveInfo: 'leave_type_save_info',
  };

  public static loan = {
    approve: 'loan_approve',
    getAll: 'loan_get_all',
    getBySalary: 'loan_get_by_salary_id',
    saveInfo: 'loan_save_info',
  };

  public static notice = {
    getAll: 'notice_get_all',
    saveInfo: 'notice_save_info',
  };

  public static payroll = {
    allowanceDependency: 'payroll_allowance_dependency',
    allowanceType: 'payroll_allowance_type',
    salaryHead: 'payroll_salary_head',
  };

  public static project = {
    getAll: 'project_get_all',
    getByID: 'project_get_by_id',
    saveInfo: 'project_save_info',
    updateInfo: 'project_update_info',
  };

  public static salaryConfig = {
    delete: 'salary_config_delete',
    getAll: 'salary_config_get_all',
    getByCategory: 'salary_config_get_by_category',
    getById: 'salary_config_get_by_id',
    saveInfo: 'salary_config_save_info',
    updateInfo: 'salary_config_update_info',
  };

  public static salaryRecord = {
    salaryHead: 'salary_record_salary_head',
    updateInfo: 'salary_record_update_info',
  };

  public static salaryUnit = {
    delete: 'salary_unit_delete',
    getAll: 'salary_unit_get_all',
    getById: 'salary_unit_get_by_id',
    saveInfo: 'salary_unit_save_info',
    updateInfo: 'salary_unit_update_info',
  };

  public static sbu = {
    getAll: 'sbu_get_all',
  };
}





<!--<div class="header-container row col-md-12">-->
<!--      <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">-->
<!--        <nb-icon icon="menu-2-outline"></nb-icon>-->
<!--      </a>-->
<!--</div>-->
<div class="logo-container row col-md-12" style="display: flex; justify-content: center; align-items: center">


  <img class="logo-img" class="logo-img"
       src="../../../../assets/images/synesis-logo.png">
  <p class="main-text">HRIS</p>

</div>

<div class="custom-modal" *ngIf="isModalOpen">
  <div class="custom-modal-content">
    <span class="close-modal" (click)="closeModal()">&times;</span>
    <h2>Notification Details</h2>
    <p>{{notification?.massage}}</p>
  </div>
</div>

import {Component, OnDestroy, OnInit} from '@angular/core';
import {NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService} from '@nebular/theme';

import {UserData} from '../../../@core/data/users';
import {LayoutService} from '../../../@core/utils';
import {map, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {JwtUtilService} from '../../../services/shared-services/jwt-util.service';
import {AuthenticationService} from '../../../authentication/authentication.service';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  userName: string;
  picture = 'assets/images/avatar.png';
  toggle: boolean = true;
  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  // userMenu = [{ title: 'Profile' }, { title: 'Reset Password' }, { title: 'Log out', link: '/auth/logout', }];
  userMenu = [{title: 'Profile', link: '/pages/profile'},
    {title: 'Reset Password', link: '/auth/reset-password'},
    {title: 'Log out', link: ''}];

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private userService: UserData,
              private layoutService: LayoutService,
              private breakpointService: NbMediaBreakpointsService,
              private authService: AuthenticationService,
              private jwtUtilService: JwtUtilService) {
  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;
    const tokenInfo = localStorage.getItem('auth_app_token');
    const userInfo = this.jwtUtilService.getDataFromJWT(JSON.parse(tokenInfo).value);
    this.user = this.authService.getUser();
    const stringArray = this.user?.name.split(' ');

    this.userName = this.authService.getUser()?.name;

    this.userService.getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => this.user = users.nick);

    const {xl} = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({name}) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();
    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  getNameFromToken() {

  }

  onMenuSelect($event: MouseEvent) {
    // console.log('value: ', $event);
  }
}

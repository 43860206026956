import {Component, OnInit} from '@angular/core';
import {LayoutService} from '../../../@core/utils';
import {NbSidebarService} from '@nebular/theme';
import {NotificationUpdateRequest} from '../../../pages/notification/notification.model';
import {AuthenticationService} from '../../../authentication/authentication.service';
import {ApiService} from '../../../services/shared-services/api.service';
import {ApiUrlService} from '../../../services/shared-services/api-url.service';
import {AlertService} from '../../../services/shared-services/alert.service';
import {API_URLS} from '../../../constants/api-urls';
import {HttpParams} from '@angular/common/http';

@Component({
    selector: 'ngx-logo',
    templateUrl: './logo.component.html',
    styleUrls: ['./logo.component.scss'],
})
export class LogoComponent implements OnInit {
    isModalOpen = false;
    serviceUrl: string;
    userRole = null;
    userId = null;
    notificationList = [];
    notification: any;
    page: number = 0;
    size: number = 10;
    count: number;
    dropdownVisible = false;
    notificationVisible = false;
    notificationAll = false;
    updateRequest: NotificationUpdateRequest = new NotificationUpdateRequest();

    constructor(private layoutService: LayoutService,
                private sidebarService: NbSidebarService,
                private authService: AuthenticationService,
                private apiService: ApiService,
                private apiUrlService: ApiUrlService,
                private alertService: AlertService) {
        this.apiUrlService.getUrl().subscribe((x) => {
            this.serviceUrl = x.url;
        });
        this.userRole = authService.getRole();
        this.userId = authService.getUser();
    }

    ngOnInit(): void {
        this.getNotificationList();
        this.getCount();
    }

    getNotificationList() {
        this.apiService.get(this.serviceUrl + API_URLS.NOTIFICATION.GET_LIST).subscribe(
            (res) => {
                this.notificationList = res.content;
                this.size = res.totalElements;
            });
    }

    closeDropdown(): void {
        this.dropdownVisible = false;
    }

    getCount() {
        this.apiService.get(this.serviceUrl + API_URLS.NOTIFICATION.GET_COUNT).subscribe(
            (res) => {
                this.count = res.replyMessage;

            });
    }

    showMore() {
        return null;
    }

    toggleDropdown(): void {
        this.dropdownVisible = !this.dropdownVisible;
        console.log(this.dropdownVisible);
    }

    colour(seen: boolean) {
        if (seen) {
            return '#ffffff';
        } else {
            return '#afafaf';
        }
    }
    getNotification(notificationId: number) {
        const params = new HttpParams()
            .set('notificationId', notificationId);
        this.apiService.get(this.serviceUrl + API_URLS.NOTIFICATION.GET_NOTIFICATION, params).subscribe(
            (res) => {
                this.notification = res.replyMessage;
                this.notificationVisible = !this.notificationVisible;
                if (this.notification.seen === false) {
                    this.updateRequest.notificationId = this.notification?.id;
                    this.updateRequest.employeeId = this.notification?.createdBy;
                    this.openModal();
                    this.apiService.put(this.serviceUrl +
                        API_URLS.NOTIFICATION.UPDATE_NOTIFICATION, this.updateRequest).subscribe(
                        () => {
                        });
                }
            });
    }

    openModal(): void {
        this.isModalOpen = true;
        this.closeDropdown(); // Close the dropdown when the modal is opened
    }

    closeModal(): void {
        this.isModalOpen = false;
        this.getNotificationList();
        this.getCount();
    }

    toggleSidebar(): boolean {
        this.sidebarService.toggle(true, 'menu-sidebar');
        this.layoutService.changeLayoutSize();
        return false;
    }
}

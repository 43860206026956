import {Component} from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
      <div >
    <span >
     &copy;All rights reserved by <b><a href="https://synesisit.com.bd/" target="_blank"><img class="logo"
                                                                                              src="../../../../assets/images/synesisiit2.png"></a></b>
    </span>

          <span class="footer-design">
          <a href="#" target="_blank" style="color: #8f9bb3">
              <nb-icon icon="facebook"></nb-icon>
          </a>
          <a href="https://www.linkedin.com/company/synesis-it-limited/" target="_blank" style="color: #8f9bb3">
              <nb-icon icon="linkedin"></nb-icon>
          </a>
        </span>
      </div>
  `,
})
export class FooterComponent {
}

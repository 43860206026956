<div class="row" style="margin: 10px 10px 10px 10px">
  <div class="col-md-12">
    <div class="header-container">

      <!--                                            <div class="notification-icon" (click)="toggleDropdown()">-->
      <!--                                                <i class="fa fa-bell" aria-hidden="true">-->
      <!--                                                    <span class="badge" *ngIf="count > 0">{{ count }}</span>-->
      <!--                                                </i>-->
      <!--                                            </div>-->

      <nb-actions size="small">
        <nb-action class="user-action" *nbIsGranted="['view', 'user']">
          <nb-user [nbContextMenu]="userMenu" [onlyPicture]="userPictureOnly"
                   [name]="userName"
                   [picture]="picture">
          </nb-user>
        </nb-action>
      </nb-actions>
    </div>


    <div class="notification-dropdown" [class.show]="dropdownVisible">
      <div class="dropdown-header"></div>
      <div class="dropdown-item-container">
        <div class="dropdown-item" [style.background-color]="colour(notification.seen)"
             *ngFor="let notification of notificationList"
             (click)="getNotification(notification.id)">
          <li>{{ notification.subject }}<p
            style="font-size: 7px; float: right">{{notification.timeBetween}}</p></li>
        </div>
      </div>
      <div class="dropdown-footer-only" *ngIf="5 >= size"></div>
      <div class="dropdown-footer" *ngIf="5 < size">
        <div class="dropdown-item see-more " (click)="showMore()">
          See More
        </div>
        <div class="dropdown-item close-button " (click)="closeDropdown()">
          Close
        </div>
      </div>
    </div>
  </div>
</div>

import {Component, OnInit} from '@angular/core';
import {NotificationUpdateRequest} from '../../../pages/notification/notification.model';
import {HttpParams} from '@angular/common/http';
import {API_URLS} from '../../../constants/api-urls';
import {AlertService} from '../../../services/shared-services/alert.service';
import {ApiUrlService} from '../../../services/shared-services/api-url.service';
import {ApiService} from '../../../services/shared-services/api.service';
import {AuthenticationService} from '../../../authentication/authentication.service';
import {NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService} from '@nebular/theme';
import {LayoutService} from '../../../@core/utils';
import {Subject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import {UserData} from '../../../@core/data/users';
import {JwtUtilService} from '../../../services/shared-services/jwt-util.service';
import {Router} from '@angular/router';

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  template: `
      <nb-layout windowMode>
          <nb-layout-header subheader class="mobile-header">
              <ngx-header class="mobile-header"></ngx-header>
          </nb-layout-header>

          <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive>

              <ngx-logo></ngx-logo>
              <!--                            <ngx-header></ngx-header>-->
              <ng-content select="nb-menu"></ng-content>

          </nb-sidebar>

          <nb-layout-column class="column-layout">
              <nb-card>
                  <ngx-header-info></ngx-header-info>
              </nb-card>
              <div class="custom-modal" *ngIf="isModalOpen">
                  <div class="custom-modal-content">
                      <span class="close-modal" (click)="closeModal()">&times;</span>
                      <h6>Notification</h6>
                      <p>{{notification?.subject}}.</p>
                      <h6>Details</h6>
                      <p>{{notification?.massage}} by {{notification?.updateByName}}.</p>
                  </div>
              </div>
              <ng-content select="router-outlet"></ng-content>
          </nb-layout-column>

          <nb-layout-footer>
              <ngx-footer></ngx-footer>
          </nb-layout-footer>
      </nb-layout>
  `,
})
export class OneColumnLayoutComponent implements OnInit {
  isModalOpen = false;
  serviceUrl: string;
  userRole = null;
  userId = null;
  notificationList = [];
  notification: any;
  page: number = 0;
  size: number = 10;
  count: number;
  dropdownVisible = false;
  notificationVisible = false;
  notificationAll = false;
  updateRequest: NotificationUpdateRequest = new NotificationUpdateRequest();


  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  userName: string;
  picture = 'assets/images/avatar.png';

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  // userMenu = [{ title: 'Profile' }, { title: 'Reset Password' }, { title: 'Log out', link: '/auth/logout', }];
  userMenu = [{title: 'Profile', link: '/pages/profile'},
    {title: 'Reset Password', link: '/reset-password'},
    {title: 'Log out', link: '/logout'}];
  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private userService: UserData,
              private layoutService: LayoutService,
              private breakpointService: NbMediaBreakpointsService,
              private authService: AuthenticationService,
              private jwtUtilService: JwtUtilService,
              private apiService: ApiService,
              private apiUrlService: ApiUrlService,
              private router: Router) {
    this.apiUrlService.getUrl().subscribe((x) => {
      this.serviceUrl = x.url;
    });
    this.userRole = authService.getRole();
    this.userId = authService.getUser();
  }
  ngOnInit(): void {
    this.currentTheme = this.themeService.currentTheme;
    const tokenInfo = localStorage.getItem('auth_app_token');
    const userInfo = this.jwtUtilService.getDataFromJWT(JSON.parse(tokenInfo).value);
    this.user = this.authService.getUser();
    const stringArray = this.user?.name.split(' ');

    this.userName = this.authService.getUser()?.name;

    this.userService.getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => this.user = users.nick);

    const {xl} = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({name}) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);
    this.getNotificationList();
    this.getCount();
  }
  getNotificationList() {
    const params = new HttpParams()
      .set('size', this.size);
    this.apiService.get(this.serviceUrl + API_URLS.NOTIFICATION.GET_LIST, params).subscribe(
      (res) => {
        this.notificationList = res.content;
        this.size = res.totalElements;
      });
  }

  closeDropdown(): void {
    this.dropdownVisible = false;
  }

  getCount() {
    this.apiService.get(this.serviceUrl + API_URLS.NOTIFICATION.GET_COUNT).subscribe(
      (res) => {
        this.count = res.replyMessage;

      });
  }

  showMore() {
    localStorage.setItem('size', String(this.size));
    this.router.navigate(['/pages/notification']);
  }

  toggleDropdown(): void {
    this.dropdownVisible = !this.dropdownVisible;
    console.log(this.dropdownVisible);
  }

  colour(seen: boolean) {
    if (seen) {
      return '#ffffff';
    } else {
      return '#afafaf';
    }
  }
  getNotification(notificationId: number) {
    const params = new HttpParams()
      .set('notificationId', notificationId);
    this.apiService.get(this.serviceUrl + API_URLS.NOTIFICATION.GET_NOTIFICATION, params).subscribe(
      (res) => {
        this.notification = res.replyMessage;
        this.notificationVisible = !this.notificationVisible;
          this.updateRequest.notificationId = this.notification?.id;
          this.updateRequest.employeeId = this.notification?.createdBy;
          this.openModal();
        if (this.notification.seen === false) {
          this.apiService.put(this.serviceUrl +
            API_URLS.NOTIFICATION.UPDATE_NOTIFICATION, this.updateRequest).subscribe(
            () => {
            });
        }
      });
  }

  openModal(): void {
    this.isModalOpen = true;
    this.closeDropdown(); // Close the dropdown when the modal is opened
  }

  closeModal(): void {
    this.isModalOpen = false;
    this.getNotificationList();
    this.getCount();
  }

  // ngOnDestroy() {
  //   this.destroy$.next();
  //   this.destroy$.complete();
  // }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();
    return false;
  }
}

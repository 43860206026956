import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { NbAuthService } from '@nebular/auth';
import { tap } from 'rxjs/operators';
import { PermissionList } from "./authentication/permission-list";
import { AuthenticationService } from "./authentication/authentication.service";

@Injectable()
export class AuthGuard implements CanActivate {
  permissionList = PermissionList;
  permissions = [];

  constructor(private authService: NbAuthService,
    private router: Router,
    private authentication: AuthenticationService) {
    this.permissions = authentication.getPermissions();
  }

  canActivate(route) {
    const foundInPermissionList = false;
    return this.authService.isAuthenticated()
      .pipe(
        tap(authenticated => {
          if (!authenticated) {
            this.router.navigate(['']);
            localStorage.clear();
          }
        }),
      );
  }
}
